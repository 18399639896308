<template>
  <v-btn text @click="$emit('passBack', 'AllocationRadioGroup')"
    ><v-icon small left>mdi-chevron-left</v-icon>Back</v-btn
  >
</template>
<script>
export default {
  name: "AllocationBackBtn",
  data() {
    return {};
  },
};
</script>